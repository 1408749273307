var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('Login',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(
      form => {
        this.loginForm = form;
      }
    ),expression:"\n      form => {\n        this.loginForm = form;\n      }\n    "}],attrs:{"defaultActiveKey":_vm.type,"onTabChange":_vm.onTabChange,"onSubmit":_vm.handleSubmit}},[_c('Tab',{key:"account",attrs:{"tab":"账户密码登录"}},[_c('UserName',{attrs:{"name":"phone","placeholder":"账户","rules":[
          {
            required: true,
            message: '请输入账户!'
          }
        ]}}),_c('Password',{attrs:{"name":"password","placeholder":"密码","rules":[{ required: true, message: '请输入密码！' }],"onPressEnter":e => {
            e.preventDefault();
            this.form.validateFields(this.handleSubmit);
          }}})],1),_c('Submit',{attrs:{"loading":_vm.submitting}},[_vm._v(" 登录 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }