<template>
  <div class="main">
    <Login
      :defaultActiveKey="type"
      :onTabChange="onTabChange"
      :onSubmit="handleSubmit"
      v-ant-ref="
        form => {
          this.loginForm = form;
        }
      "
    >
      <Tab key="account" tab="账户密码登录">
        <UserName
          name="phone"
          placeholder="账户"
          :rules="[
            {
              required: true,
              message: '请输入账户!'
            }
          ]"
        />
        <Password
          name="password"
          placeholder="密码"
          :rules="[{ required: true, message: '请输入密码！' }]"
          :onPressEnter="
            e => {
              e.preventDefault();
              this.form.validateFields(this.handleSubmit);
            }
          "
        />
      </Tab>

      <Submit :loading="submitting">
        登录
      </Submit>
    </Login>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Login from "@/components/Login";
import axios from "axios";

const { Tab, UserName, Password, Submit } = Login;

export default {
  components: {
    Login,
    Tab,
    UserName,
    Password,
    Submit
  },
  data() {
    return {
      type: "account",
      autoLogin: true,
      submitting: false,
      isLoginError: false,
      smsSendTime: 60,
      smsSendBtn: false,
      alert: "",
      count: 0,
      tabs: []
    };
  },
  computed: {
    ...mapState("login", {
      status: state => state.status
    })
  },
  methods: {
    ...mapActions("login", ["smsCodeLogin"]),
    onGetCaptcha(e) {
      e.preventDefault()
      const {
        loginForm: { validateFields },
      } = this
      validateFields(['phone'], { force: true }, (err, values) => {
        if (!err) {
          this.smsSendBtn = true
          const interval = window.setInterval(() => {
            if (this.smsSendTime-- <= 0) {
              this.smsSendTime = 60
              this.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const hide = this.$message.loading('验证码发送中..', 0)
          axios({
            url:   this.$baseUrl + "login/code",
            method: "GET",
            params: { phone: values.phone, "clientType": "WEB" }
          }).then(res => {
            if (res.data.returnCode == "200") {
              setTimeout(hide, 1)
              this.$message.success("验证码获取成功。");
            } else {
              setTimeout(hide, 1)
              clearInterval(interval)
              this.smsSendTime = 60
              this.smsSendBtn = false
              this.$message.error(`${res.data.returnMsg}`);
            }
          }).catch(error => {
            setTimeout(hide, 1)
            clearInterval(interval)
            this.smsSendTime = 60
            this.smsSendBtn = false
            this.$message.error(error.message);
          })
        }
      })
    },
    handleSubmit(err, values) {
      const { type } = this.$data;
      if (!err) {
        this.submitting = true;
        this.smsCodeLogin({
          ...values,
          type,
        }).then(res => {
          this.submitting = false;
          this.handleResponse(res);
        });
      }
    },
    handleResponse(res) {
      this.alert = res;
      if (res.returnCode === "200") {
        this.$message.success("登录成功，欢迎回来！");
      } else {
        this.$message.error(`${res.returnMsg}`);
      }
    },
    onTabChange(type) {
      this.type = type;
    }
  }
};
</script>

<style scoped lang="less">
@import "~ant-design-vue/lib/style/themes/default.less";

.main {
  width: 388px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }

  .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }

  .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;

    .register {
      float: right;
    }
  }
  .login {
    /deep/ .ant-tabs .ant-tabs-bar {
      margin-bottom: 24px;
      text-align: center;
      border-bottom: 0;
    }

    /deep/ .ant-form-item {
      margin: 0 2px 24px;
    }

    .icon {
      margin-left: 16px;
      color: rgba(0, 0, 0, 0.2);
      font-size: 24px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: @primary-color;
      }
    }

    .other {
      margin-top: 24px;
      line-height: 22px;
      text-align: left;

      .register {
        float: right;
      }
    }

    .prefixIcon {
      color: @disabled-color;
      font-size: @font-size-base;
    }

    .submit {
      width: 100%;
      margin-top: 24px;
    }
  }
}
</style>
